<template>
  <div>
    <div v-if="loading === true">
      <br /><br />
      <div align="center">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <br /><br /><br />
        <h1>ระบบกำลังประมวลผล</h1>
      </div>
    </div>
    <div id="loading" style="display: block">
      <h2>Wifi Network</h2>
      <p>SSID & Bandwidth & Session</p>
      <br />
      <b-card>
        <h6>SSID (ชื่อไวไฟ)</h6>
        <hr />
      

        <div class="grid-wrap3">
          <div class="mygrid-item" v-for="item in hotspot" :key="item.id">
            <div class="thumb">
             
              <b-form-group>
                <p>
                Mac: <code>{{ item.mac }}</code> <br>
                Description:{{ item.description }}
              </p>
                <b-form-input
                  size="sm"
                  placeholder="ตัวอย่าง: @SOCIO WIFI 5G"
                  v-model="item.SSID"
                  required
                />
              </b-form-group>
              <b-alert
                :id="item.mac"
                style="display: none"
                variant="danger"
                show
              >
                <span>ไม่สามารถเปลี่ยนชื่อไวไฟได้โปรดตรวจสอบอุปกรณ์ของคุณ</span>
              </b-alert>
            </div>
          </div>

          <!-- /.mygrid-item -->
        </div>
      </b-card>
      <b-card lg="4">
        <!-- <b-row>
        <b-col cols="5" style="margin-left: 5em">
          <b-form-group label="SSID (ชื่อไวไฟ)">
            <b-form-input
              size="sm"
              placeholder="ตัวอย่าง: @SOCIO WIFI 5G"
              v-model="ssid"
            />
          </b-form-group>
          <b-alert v-if="check.ssid == true" variant="danger" show>
            <span>Please Enter</span>
          </b-alert>
        </b-col>
      </b-row> -->

        <b-row>
          <b-col cols="3" style="margin-left: 5em">
            <label for="">ตั้งค่าเวลาการใช้งาน</label>
            <b-form-select
              v-model="selected"
              :options="options"
              size="sm"
              class="mt-1"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col style="margin: 40px 5em">
            <p>
              Download <code>{{ upload }} Mbps</code>
            </p>
            <vue-slider v-model="upload" :lazy="true" :direction="direction" />
          </b-col>

          <b-col style="margin: 40px 5em">
            <p>
              Upload <code> {{ download }} Mbps</code>
            </p>
            <vue-slider
              v-model="download"
              :lazy="true"
              :direction="direction"
            />
          </b-col>
        </b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="add_setting()"
        >
          บันทึกการตั้งค่า
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BFormSelect,
  BAlert,
  BFormFile,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";

import VueSlider from "vue-slider-component";
import store from "@/store/index";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BFormSelect,
    BAlert,
    BFormFile,
    BImg,
    VueSlider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: "30",
      loading: false,
      options: [
        { value: "1800", text: "30 นาที" },
        { value: "3600", text: "1 ชั่วโมง" },
        { value: "7200", text: "2 ชั่วโมง" },
        { value: "14400", text: "4 ชั่วโมง" },
        { value: "28800", text: "6 ชั่วโมง" },
      ],
      data_network: [],
      hotspot: [],
      data_stores: "",
      totalRows: "",
      name_user: "",
      count_setting: 0,
      check: {
        ssid: false,
        download: false,
        upload: false,
        Session_Timeout: false,
        Idle_Timeout: false,
      },
      ssid: null,
      download: 100,
      upload: 100,
      Session_Timeout: null,
      Idle_Timeout: null,
    };
  },
  created() {},
  destroyed() {},
  watch: {},
  mounted() {
    this.name_user = JSON.parse(localStorage.getItem("userData"));
    this.loaddata_store_id();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
  methods: {
    loaddata_store_id() {
      console.log("  this.name_user :>> ", this.name_user);
      const params = {
        user_main: this.name_user.user_main,
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("get_setting_network", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("response.data :>> ", response.data);
          this.hotspot = response.data.hotspot;
          this.data_network = response.data.data[0];
          this.download = this.data_network.BandwidthDownload / 1000000;
          this.upload = this.data_network.BandwidthUpload / 1000000;
          this.selected = this.data_network.sessionTimeout;
          this.ssid = this.data_network.SSID;
        })
        .catch((error) => {});
    },
    add_setting() {
      this.check.ssid = false;
      console.log("this.hotspot :>> ", this.hotspot);
      var userData = JSON.parse(localStorage.getItem("userData"));
      const params = {
        store_id: localStorage.getItem("store_id"),
        download: this.download,
        upload: this.upload,
        time_use: this.selected,
        user_main: userData.user_main,
        hotspots: this.hotspot,
      };
      console.log("params :>> ", params);
      if (this.hotspot) {
        this.loading = true;
        document.getElementById("loading").style.display = "none";
        api
          .post("/add_setting_network", params, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.loading = false;
            document.getElementById("loading").style.display = "block";
            console.log("response", response.data);
            for (const element of response.data) {
              console.log("element.mac :>> ", element.data.mac);
              console.log("element.status :>> ", element.data.status);
              if (element.data.status === "Error") {
                document.getElementById(element.data.mac).style.display =
                  "block";
              }
            }
            if (response) {
              this.$swal({
                icon: "success",
                title: "success",
                showConfirmButton: false,
                timer: 3000,
              });
              this.loaddata_store_id();
            }
          })
          .catch((error) => {
            this.loading = false;
            document.getElementById("loading").style.display = "block";
            this.$swal({
              title: "Error!",
              text: " เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.$swal({
          title: "Error!",
          text: " ตรวจสอบข้อมูลใหม่อีกครั้ง!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.ex4 {
  display: flex;
}
.ex4 > .item {
  font-size: 10px;
  margin-left: 10px;
  flex-basis: 100%;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fe718d;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ------------- Custom Grid --------------- */
.grid-wrap3 {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin: auto -0.5em;
}

.mygrid-item {
  padding: 0.5em;
  overflow: hidden;
  position: relative;
}

.mygrid-item .thumb {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.mygrid-item .thumb img {
  width: 100%;
  display: block;
  border-radius: 10px;
  object-fit: scale-down;
}

.mygrid-item a {
  display: block;
}

.mygrid-item a .thumb {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.mygrid-item a:hover .thumb {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
</style>
